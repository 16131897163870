.App-header {
    background-image: url("./assets/images/recipe.jpeg");
     
    /* You must set a specified height */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    background-color: #cccccc;
}

#banner {
     
}

h2{
    color: chocolate !important;
    margin-top: 1.5rem !important;
    
}
 
h4{
    color: black !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}
footer {
    background-color: chocolate;
    text-align: center;
    color: khaki;
    margin-top: 3rem;
}

.icon{
    color: orange;
}
 